import React from "react";
import "../../styles.css";



export default function Unavailable() {
    
  return (
    <div className="unavailable">
      This post is unavailable
    </div>
  )
}